import { createPromoDataAttributes } from 'JS/utilities/analytics';
import { PrebuiltCategoryHeaderDataSubCategory } from 'JS/dxp/types/widgets/prebuilt-header-widget';


export function createPrebuiltHeaderPromoAttributes(title: string, subtitle: string) {
	return createPromoDataAttributes({
		promoId: `${title}.`, //No Shop all button, so just using the title for this.
		creativeSlot: "dxp_header_0",
		creativeName: `dxp_header_${title}`,
		promoName: `${title}. ${subtitle}`
	});
}

export function createPrebuiltHeaderSubcatPromoAttributes(title: string, subtitle: string, cat: PrebuiltCategoryHeaderDataSubCategory, idx: number) {
	return createPromoDataAttributes({
		promoId: cat.url.replace("https://www.farmandfleet.com", ""),
		creativeSlot: `dxp_header_${idx + 1}`,
		creativeName: `dxp_header_${title}`,
		promoName: `${title}. ${subtitle}`
	});
}