import { render, staticRenderFns } from "./HorseCareAndTackHeader.vue?vue&type=template&id=0046ae50&scoped=true"
import script from "./HorseCareAndTackHeader.vue?vue&type=script&setup=true&lang=ts"
export * from "./HorseCareAndTackHeader.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./HorseCareAndTackHeader.vue?vue&type=style&index=0&id=0046ae50&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0046ae50",
  null
  
)

export default component.exports